import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 1,
  class: "c-form-input-text-before"
}
const _hoisted_3 = {
  key: 2,
  class: "c-form-input-text-before-icon icon is-left"
}
const _hoisted_4 = ["type", "id", "placeholder", "minlength", "maxlength", "autocomplete", "autofocus", "required", "disabled"]
const _hoisted_5 = {
  key: 3,
  class: "c-form-input-text-after-icon icon is-right"
}
const _hoisted_6 = {
  key: 4,
  class: "c-form-input-text-after"
}
const _hoisted_7 = {
  key: 5,
  class: "c-form-input-text-description"
}
const _hoisted_8 = {
  key: 6,
  class: "c-form-input-text-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'c-form-input-text control',
      _ctx.elementID,
      {
        hasError: _ctx.hasError,
        isDisabled: _ctx.isDisabled,
        hasLabel: _ctx.$slots.default,
        'has-icons-left': _ctx.$slots['before-icon'],
        'has-icons-right': _ctx.$slots['after-icon'],
      },
    ]),
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "c-form-input-text-label",
          for: _ctx.elementID
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.$slots.before)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "before")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots['before-icon'])
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _renderSlot(_ctx.$slots, "before-icon")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      ref: "inputText",
      type: _ctx.isPassword ? 'password' : _ctx.isEmail ? 'email' : 'text',
      id: _ctx.elementID,
      class: "c-form-input-text-input input is-primary py-3 px-4",
      placeholder: _ctx.placeholder,
      minlength: _ctx.min,
      maxlength: _ctx.max,
      autocomplete: _ctx.autocomplete,
      autofocus: _ctx.hasAutofocus,
      required: _ctx.isRequired,
      disabled: _ctx.isDisabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEmit && _ctx.handleEmit(...args)))
    }, null, 40, _hoisted_4),
    (_ctx.$slots['after-icon'])
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _renderSlot(_ctx.$slots, "after-icon")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.after)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "after")
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.hasError.description), 1))
      : (_ctx.$slots.description)
        ? (_openBlock(), _createElementBlock("small", _hoisted_8, [
            _renderSlot(_ctx.$slots, "description")
          ]))
        : _createCommentVNode("", true)
  ], 6))
}